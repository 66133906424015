// App.js
import React from 'react';
import './App.css';
import Header from './components/Header.js';
import Search from './components/Search.js';
import CurrentWeather from './components/CurrentWeather.js';
import Forcast from './components/Forcast.js';
import { ThemeProvider } from './ThemeContext';
import { useState } from 'react';

function App() {
  const [data, setData] = useState('');
  const [location, setLocation] = useState("");
  const apiKey = 'f0da2b8ca47c4ec2a57132038241101';

  const fetchData = async () => {
    try {
      const response = await fetch(`https://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=${location}&days=4&aqi=yes&alerts=yes`)
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <ThemeProvider>
      <>
        <Header />
        <Search setLocation={setLocation} fetchData={fetchData} location={location} />
        {data && <CurrentWeather data={data} />}
        {data && <Forcast data={data.forecast} />}
      </>
    </ThemeProvider>
  );
}

export default App;
