import React from 'react';
import '../App.css';
import { useTheme } from '../ThemeContext';

export default function Forcast({ data }) {
    const { lightmode } = useTheme();

    return (
        <div className={`container text-center mt-3 mb-4 ${lightmode ? 'light-theme' : 'dark-theme'}`}>

            <div className='row p-3 mb-3'>
                <div className='col d-flex justify-content-between'>
                    <h3>Forecast</h3>
                </div>
            </div>

            {data.forecastday && data.forecastday.map((forecastItem, index) => (
                <div className="row mb-3" key={index}>
                    <div className="col boldName">
                        {forecastItem.date}
                    </div>
                    <div className="col">
                        <span className='boldName'>Temp: </span> {forecastItem.day.mintemp_c}/{forecastItem.day.maxtemp_c}°C
                    </div>
                    <div className="col">
                        <img src={forecastItem.day.condition.icon} alt='weather' />
                    </div>
                </div>
            ))}
        </div>
    );
}
