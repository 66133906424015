import React from 'react';
import '../App.css';
import { useTheme } from '../ThemeContext';

export default function CurrentWeather({ data }) {
    const { lightmode } = useTheme();

    return (
        <div className={`container text-center mt-5 grid ${lightmode ? 'light-theme' : 'dark-theme'}`}>

            <div className="p-3 mb-3">
                <div className="col d-flex justify-content-between">
                    <h3>{data.location.name}</h3>
                </div>
                <div className="">
                    <div className="col d-flex justify-content-between">
                        <h6>{data.location.region}, {data.location.country}</h6>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col temp">
                    {data.current.temp_c}°C
                </div>
                <div className="col">
                    <img src={data.current.condition.icon} alt='weather' />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <span className='boldName'> Feel : </span>{data.current.feelslike_c}°C
                </div>
                <div className="col">
                    <span className='boldName'> Temp : </span>{data.current.temp_f}°F
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <span className='boldName'> Humidity :</span> {data.current.humidity}
                </div>
                <div className="col">
                    <span className='boldName'> Pressure :</span> {data.current.pressure_in}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <span className='boldName'> Wind :</span> {data.current.wind_kph}kph
                </div>
                <div className="col">
                    <span className='boldName'> Wind-deg:</span> {data.current.wind_degree}°{data.current.wind_dir}
                </div>
            </div>
            <div className='row mb-3'>
                <div className="col">
                    <span className='boldName'> Wind-dir :</span> {data.current.uv}
                </div>
                <div className="col">
                    <span className='boldName'> Gust :</span> {data.current.gust_kph}
                </div>
            </div>
            <div className='row'>
                <div className="col">
                    <span className='boldName'> Visible-light :</span> {data.current.vis_km}km
                </div>
                <div className="col">
                    <span className='boldName'> Visible-light :</span> {data.current.vis_miles}miles
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col ">
                    <strong>
                        last_updated : {data.current.last_updated}
                    </strong>
                </div>
            </div>
        </div>
    );
}
