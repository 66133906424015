// ThemeContext.js
import React, { createContext, useState, useContext } from 'react';

const ThemeContext = createContext();

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

export const ThemeProvider = ({ children }) => {
    const [lightmode, setLightMode] = useState(true);

    const toggleMode = () => {
        setLightMode((prevMode) => !prevMode);
    };

    return (
        <ThemeContext.Provider value={{ lightmode, toggleMode }}>
            {children}
        </ThemeContext.Provider>
    );
};
