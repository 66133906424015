import React from 'react';
import '../App.css';

export default function Search({ location, setLocation, fetchData }) {

    const submit = (e) => {
        e.preventDefault();
        if (!location) {
            alert("Please Enter a valid Location");
        }
        else {
            fetchData();
            setLocation("");
        }
    }

    return (
        <div className="mt-5">
            <div className="input-contain justify-content-center align-items-center">
                <form >
                    <div className="d-flex align-items-center justify-content-around">
                        <input
                            className="form-control input"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={location}
                            onChange={(e) => { setLocation(e.target.value) }}
                        />
                        <button className="btn btn-success my-1" type="button" onClick={submit}>
                            Search
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
