// Header.js
import React from 'react';
import '../App.css';
import { MdOutlineLightMode, MdDarkMode } from 'react-icons/md';
import { useTheme } from '../ThemeContext';

export default function Header() {
    const { lightmode, toggleMode } = useTheme();
    const style = { color: "yellow" }

    return (
        <>
            <nav className={`navbar ${lightmode ? 'light-theme' : 'dark-theme'}`}>
                <div className="container-md container-sm">
                    <a className="navlink" href="/">
                        <strong >Weather App</strong>
                    </a>
                    <form className="d-flex">
                        <button className="btn" type="button" onClick={toggleMode}>
                            {lightmode ? <MdDarkMode /> : <MdOutlineLightMode style={style} />}
                        </button>
                    </form>
                </div>
            </nav >
        </>
    );
}
